import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './FilterOverlay.css';

interface Category {
    name: string;
    seoFriendlyId: string;
}

interface FilterOverlayProps {
    categories: Category[]; // Adjust the type to match expected data structure
    onApplyFilter: (selectedCategories: string[]) => void;
}

const FilterOverlay: React.FC<FilterOverlayProps> = ({ categories, onApplyFilter }) => {
    const [show, setShow] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleFilterChange = (seoFriendlyId: string, isChecked: boolean) => {
        setSelectedCategories(prev => 
            isChecked ? [...prev, seoFriendlyId] : prev.filter(id => id !== seoFriendlyId)
        );
    };
    const handleApply = () => {
        onApplyFilter(selectedCategories);
        handleClose();
    };

    return (
        <>
            <button className="btn btn-dark mb-3" onClick={handleShow}>
                Filters
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="modal-dialog" contentClassName="filter-modal-content">
                <Modal.Header closeButton>
                    <Modal.Title>Filter Stores</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="container">
                    <div className="row">
                        {categories.map((category, index) => (
                            <div key={index} className="col-12 col-sm-6 col-md-3 mb-4"> {/* Ensuring responsiveness across different sizes */}
                                <div className="form-check">
                                    <input 
                                        className="form-check-input" 
                                        type="checkbox" 
                                        id={category.seoFriendlyId} 
                                        value={category.seoFriendlyId} 
                                        onChange={(e) => handleFilterChange(category.seoFriendlyId, e.target.checked)} 
                                        checked={selectedCategories.includes(category.seoFriendlyId)}
                                    />
                                    <label className="form-check-label" htmlFor={category.seoFriendlyId}>
                                        {category.name}
                                    </label>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <button className="btn btn-outline-dark" onClick={handleApply}>
                        Apply Filters
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default FilterOverlay;
