// ChatbotUI.tsx
import React, { useState } from 'react';

const ChatbotUI: React.FC = () => {
  const [messages, setMessages] = useState<string[]>([]);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const input = event.currentTarget;
      const message = input.value.trim();
      if (message) {
        setMessages([...messages, message]);
        input.value = '';
      }
    }
  };

  return (
    <div className="chatbot-ui">
      <div className="chat-messages">
        {messages.map((msg, index) => (
          <div key={index}>You: {msg}</div>
        ))}
      </div>
      <input type="text" className="chat-input" placeholder="Type a message..." onKeyPress={handleKeyPress} />
    </div>
  );
};

export default ChatbotUI;
