import React, { useRef , useState , useEffect} from "react";
import Footer from './Footer';
import Header from './Header/Header';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

import { Button, Card, Carousel } from 'react-bootstrap';
import RewardCard from './RewardCard';
import InfoCard from './InfoCard';
import EarnReawrdsInfoCard from './EarnReawrdsInfoCard';
import RewardsAccountDetailCard from './RewardsAccountDetailCard';
import config from '../config/config.json'; 

import 'bootstrap/dist/css/bootstrap.min.css';

import CarouselHome from './CarouselHome';


import {
  getTokenCuckooWL,
  setConversionRate,
  setBasisPoint,
  getBasisPoint,
  getConversionRate,

} from "../../src/utils/common";
import axios from 'axios';

export const BACKEND_SERVER_BASE_URL =
  process.env.REACT_APP_BACKEND_SERVER_BASE_URL;
  

const scrollToElement = (id: string) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
};




interface Reward {
  iconUrl: string;
  title: string;
  description: string;
  buttonText: string;
  comingSoon?: boolean | string;
  link:string;
}



const MainRewardsPage: React.FC = () => {
  const { section3Title, rewards } = config.section3;
  const [loadedProgram, setLoadedProgram] = useState(true);

  

  const conversionRate = getConversionRate();
  console.info("in home conversion rate loaded as " + conversionRate)



 




  return (
    <>
      {loadedProgram &&
      <div className="main-container">
        {/* <div className="navbar">
              <Link className="nav-links" to="#" onClick={() => scrollToElement('section1')}>Rewards Details</Link>
              <Link className="nav-links" to="#" onClick={() => scrollToElement('section2')}>Earn Rewards</Link>
              <Link className="nav-links" to="#" onClick={() => scrollToElement('section3')}>Available Rewards</Link>
              <Link className="nav-links" to="#" onClick={() => scrollToElement('section4')}>Maximize Points</Link>
              <Link className="nav-links" to="#" onClick={() => scrollToElement('section5')}>Recommended for You</Link>
  </div> */}
        
        <div className="section1" id='section1'>
          <div className="section1-left">
            <div className="advertisement">
              < CarouselHome />
            </div>
          </div>

          <div className="section1-right">
           {/* <Card className="cart-card">
              <p className="cart-card-text">
                Your cart is empty. Add rewards to your cart and start earning benefits.
              </p>
              <Link className="cart-shopping-link" to="/products">
                See Rewards Options →
              </Link>
            </Card> */}

            <div className="rewards-account-details">
              <RewardsAccountDetailCard conversionRate={Number(conversionRate)}/>
            </div>
            <div className="rewards-info"></div>
          </div>
        </div>
        <div className="section2" id='section2'>
          <div className="earn-rewards">
            <div className="section-title">EARN REWARDS</div>
            <EarnReawrdsInfoCard />
          </div>
          <div className="featured-rewards">
            <div className="section-title">FEATURED REWARDS</div>
            <div className="featured-rewards-content">
              <div className="featured-rewards-image">
                  <img src={process.env.PUBLIC_URL+'./assets/featuredRewards/featured-rewards.png'} alt="Featured Rewards" />
              </div>
              <div className="featured-rewards-button">
                <Link to='/products'>
                  <button type="button" className="homepage-button">
                    Explore Travel
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="section3" id='section3'>
          <div className="section3-name">{section3Title}</div>
          <div className="rewards-cards">
            {rewards.map((reward: Reward, index: number) => (
              <RewardCard
                key={index}
                iconurl={reward.iconUrl}
                title={reward.title}
                description={reward.description}
                buttonText={reward.buttonText}
                comingSoon={reward.comingSoon}
                link={reward.link}
              />
            ))}
          </div>
        </div>

        <div className="section4" id='section4'>
          <div className="section-title">MAXIMIZE YOUR REWARDS LIKE A PRO</div>
          <div className="info-cards">
            <InfoCard
              iconText= "1"
              title1="Charge and"
              title2="Pay Quickly"
              description="Use your rewards credit card for all purchases. Pay off your balance promptly to avoid interest."
            />
            <InfoCard
              iconText= "2"
              title1="Remember"
              title2="Bonus Categories"
              description="Monitor your card’s bonus categories for max rewards. Use the best card for specific categories."
            />
            <InfoCard
              iconText= "3"
              title1="Discover"
              title2="Hidden Perks"
              description="Explore card benefits like travel bonuses and discounts. Know your card’s unique perks."
            />
          </div>
        </div>

        <div className="section5" id='section5'>
          <div className="section-title">RECOMMENDED REWARDS</div>
          
          <div className="recommended-rewards-container">
          <div className='recommeded-rewards-images'>
              <img
                src={process.env.PUBLIC_URL+'./assets/RecommendedRewards/Rectangle1773.png'}
                alt="Recommended Rewards Image"
                className="background-image1"
              />
              <img
                src={process.env.PUBLIC_URL+'./assets/RecommendedRewards/recommendedRewards.png'}
                alt="Recommended Rewards Image"
                className="background-image"
              />
            </div>
            
            <div className="textbox-container">
              
              <div className="textbox">
                
                <div className="textbox-1">
                  <h3 className="heading1">
                    GET 10% OFF ON SOME COOL GIFT CARDS!
                  </h3>
                  <p className="text1">
                    Redeem for those sweet gift cards and save 10% on points. But hurry, we've got limited stock!
                  </p>
                </div>
                <div className="textbox-2">
                  <h3 className="heading2">
                    Explore the World, Earn Big Rewards!
                  </h3>
                  <p className="text2">
                    Turn your travels into treasures. Redeem points for high-value flights, luxurious hotels, and epic adventures worldwide!
                  </p>
                </div>
              </div>
            </div>

            <div className="recomended-rewards-button">
                <Link to='/products'>
                  <Button type="button" className=" btn btn-outline-dark">
                    Explore Travel
                  </Button>
                </Link>
            </div>

          </div>
        </div>
        
      </div>
      }
      </>
  );
            }

export default MainRewardsPage;
