import React from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import { BrandItem } from "../Product";
import { useSnackbar } from "notistack";
import {deletePrimaryImg} from "../../assets/delete-primary.png";
import {deleteHover} from "../../assets/delete-hover.png";
import { getBasisPoint } from "../../utils/common";

export default function CartProduct(props: { cartProduct: BrandItem }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { addCart, rmvCart, delCart } = bindActionCreators(
    actionCreators,
    dispatch
  );

  const handleRemove = () => {
    rmvCart(props.cartProduct);
    enqueueSnackbar(`Item removed from your cart successfully`, {
      variant: "error",
      autoHideDuration: 3000,
    });
  };
  const handleReduce = () => {
    if (props.cartProduct.qty === 0) return;
    delCart(props.cartProduct);
    enqueueSnackbar(`Item removed from your cart successfully`, {
      variant: "error",
      autoHideDuration: 3000
    });
  };
  const handleAdd = () => {
    addCart(props.cartProduct);
    enqueueSnackbar(`Item added in your cart successfully`, {
      variant: "success",
      autoHideDuration: 3000,
    });
  };

  const imgKey: string = "1200w-326ppi";
  const basisPoint: number = Number(getBasisPoint());

  return (
    <div className="cartProductContainer row">
      <img
        src={props.cartProduct.imageUrls[imgKey]}
        className="img-fluid col-3 p-2"
        alt={props.cartProduct.rewardName}
      />
      <div className="cartProductDescription col-7 p-2" style={{margin:"auto", width:"fit-content"}}>
        <p style={{marginBottom:"0.5rem"}}><b>{"$ " + props.cartProduct.faceValue + " " + props.cartProduct.rewardName}</b></p>
        <p style={{marginBottom:"0.5rem"}}>
          <button className="btn btn-outline-dark" onClick={handleReduce} style={{marginRight:"1rem"}}>
            -
          </button>
          <span>{props.cartProduct.qty}</span>
          <button className="btn btn-outline-dark me-2" onClick={handleAdd} style={{marginLeft:"1rem"}}>
            +
          </button>
        </p>
        <p style={{marginBottom:"0.5rem"}}>{props.cartProduct.faceValue * basisPoint} pts / card</p>
        <p style={{marginBottom:"0.5rem"}}>Sub total: <b style={{color:"#04366F"}}>{props.cartProduct.sub_total * basisPoint} pts</b></p>
      </div>
      <div
        className="col-md-2 d-flex p-2"
        style={{ flexDirection: "column", justifyContent: "space-around" }}
      >
        <button className="btn btn-outline-danger" onClick={handleRemove} style={{borderRadius: "999px", width: "fit-content", margin:"auto"}}>
          <i className="fa fa-trash-o" style={{fontSize:"20px",color:"inherit", borderRadius:"9999px"}}></i>
        </button>
      </div>
    </div>
  );
}
