import { Dispatch } from "redux";
import { BrandItem } from "../../components/Product";
import Action from "../actions";
import { ActionType } from "../action-types";

// Adding Item to Cart
export const addCart = (product: BrandItem) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.ADDITEM,
      payload: product,
    });
  };
};

// Adding Delete to Cart
export const delCart = (product: BrandItem) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.DELITEM,
      payload: product,
    });
  };
};

// Remove Item
export const rmvCart = (product: BrandItem) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.RMVITEM,
      payload: product,
    });
  };
};

// Clear Cart
export const clearCart = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.CLEARCART,
    });
  };
};

// Add Favorite Product
export const addFavoriteProduct = (product: BrandItem) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.ADD_FAVORITE_PRODUCT,
      payload: product,
    });
  };
};

// Delete Favorite Product
export const deleteFavoriteProduct = (product: BrandItem) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.DELETE_FAVORITE_PRODUCT,
      payload: product,
    });
  };
};
