import React, { useState, useEffect, useRef, useCallback, useLayoutEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../state";
import { BrandItem } from "./Product";
import { getToken, getTokenCuckooWL, getBasisPoint, getUserExtId, getProgramId } from "../utils/common";
import { useSnackbar } from "notistack";
import { BACKEND_SERVER_BASE_URL } from "../App";
import FilterOverlay from "./FilterOverlay";



export default function Stores() {
    const navigate = useNavigate();
    const [data, setData] = useState<BrandItem[]>([]);
    const [categories, setCategories] = useState([]);
    const [filter, setFilter] = useState<any[]>(data);
    const [loading, setLoading] = useState(false);
    const [currentCategory, setCurrentCategory] = useState("All");
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { addCart } = bindActionCreators(actionCreators, dispatch);
    const basisPoint = getBasisPoint();

    const [page, setPage] = useState(0); // Add page state
    const [hasMore, setHasMore] = useState(true); // State to track if more items are available
    const [currentScrollPosition, setCurrentScrollPosition] = useState(0);


    let componentMounted = true;
    const observer = useRef<IntersectionObserver | null>(null);

    const lastItemRef = useCallback((node: Element | null) => {
        if (loading) return; // Do not observe if already loading
        if (observer.current) observer.current.disconnect(); // Reset the observer on new last item

        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setPage(prevPage => prevPage + 1); // Increment page to load next chunk
            }
        });

        if (node) observer.current.observe(node);
    }, [loading, hasMore]);

    const [searchTerm, setSearchTerm] = useState<string>('');

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);

        if ((searchTerm.length)>3)
        debouncedSearchStores();
    };

    const performSearch = async () => {
        setLoading(true);
        try {
            const responseSearch = await fetch(`${BACKEND_SERVER_BASE_URL}/api/v1/cashback/stores?categories=${encodeURIComponent(searchTerm)}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    'x-program-id': `${getProgramId()}`,
                    Authorization: `Bearer ${getTokenCuckooWL()}`
                },
            });
            if (!responseSearch.ok) {
                throw new Error(`HTTP error! Status: ${responseSearch.status}`);
            }
            const brandItemsList = await responseSearch.json();
            setData(brandItemsList.content);
            setFilter(brandItemsList.content);
            ShowProducts();
        } catch (error) {
            console.error("Failed to fetch search results:", error);
        } finally {
            setLoading(false);
        }
    };
    
    const clearSearch = () => {
        setSearchTerm('');
        performSearch();
        filterAllProducts(data);
    };

    function debounce<Func extends (...args: any[]) => any>(
        func: Func,
        wait: number
      ): (...args: Parameters<Func>) => void {
        let timeout: ReturnType<typeof setTimeout> | null = null;
      
        return function(...args: Parameters<Func>) {
          const later = () => {
            timeout = null;
            func(...args);
          };
      
          if (timeout !== null) {
            clearTimeout(timeout);
          }
          timeout = setTimeout(later, wait);
        };
    }
      
    const debouncedSearchStores = debounce(performSearch, 500);
    

    const addProduct = (product: any) => {
        // TODO: change any to proper type
        const cartProduct: BrandItem = {
            brandKey: product.brandKey,
            brandName: product.brandName,
            utid: product.utid,
            rewardName: product.rewardName,
            faceValue: product.defaultFaceValue,
            currency: product.currencyCode,
            imageUrls: product.imageUrls,
            qty: 1,
            sub_total: 0,
        };
        addCart(cartProduct);
        enqueueSnackbar(`Item added to your cart successfully`, {
            variant: "success",
            autoHideDuration: 3000,
        });
    };


    const PAGE_SIZE = 100; // Set how many items you want per page
    const tagName='extraCashback'
    
    const containerRef = useRef<HTMLDivElement>(null);

    // Place useLayoutEffect here
    useLayoutEffect(() => {
        window.scrollTo(0, currentScrollPosition);
    }, [data]); // Re-run this effect when 'data' changes



    useEffect(() => {
        const getCategories = async () => {
            setLoading(true);
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    'x-program-id': 'client-a',
                    Authorization: `Bearer ${getTokenCuckooWL()}`
                }
            };
            

            const catgresponse = await fetch(
                `${BACKEND_SERVER_BASE_URL}/api/v1/cashback/stores/categories`,
                requestOptions
            );
            if (!catgresponse.ok) {
                throw new Error(`HTTP error! Status: ${catgresponse.status}`);
            }
            const categoriesList = await catgresponse.json();
            setCategories(categoriesList);
            console.log(categoriesList);
            setCurrentCategory("All");
        };
    
        if (componentMounted) {
            getCategories();
        }
    
        return () => {
            componentMounted = false;
        };
    }, []);
    
    useEffect(() => {
        const currentPos = window.scrollY;
        setCurrentScrollPosition(currentPos);
        const getProducts = async () => {
            
            if (!hasMore) return; // Stop fetching if no more items
            setLoading(true);
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    'x-program-id': `${getProgramId()}`,
                    Authorization: `Bearer ${getTokenCuckooWL()}`
                }
            };
            

            try {
                const response = await fetch(
                    `${BACKEND_SERVER_BASE_URL}/api/v1/cashback/stores?size=${PAGE_SIZE}&tagName=${tagName}&page=${page}`,
                    requestOptions
                );
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const brandItemsList = await response.json();
                setData(prev => [...prev, ...brandItemsList.content]); // Append new items
                setFilter(prev => [...prev, ...brandItemsList.content]);
                setHasMore(brandItemsList.content.length === PAGE_SIZE); // Assume if less than PAGE_SIZE, it's the end
            } catch (error) {
                console.error("Failed to fetch products:", error);
            } finally {
                setLoading(false);
            }
        };
    
        if (componentMounted) {
            getProducts();
        }
    
        return () => {
            componentMounted = false;
        };
    }, [page]); // Fetch more items when page changes
    



    const Loading = () => {
        return (
            <>
                <div className="col-md-3">
                    <Skeleton height={350} />
                </div>

                <div className="col-md-3">
                    <Skeleton height={350} />
                </div>

                <div className="col-md-3">
                    <Skeleton height={350} />
                </div>
            </>
        );
    };
    const filterAllProducts = (data: any) => {
        setFilter(data);
        setCurrentCategory("All");
    };

    const filterProduct = (cat: any) => {
        // TODO: change any to proper type
        console.log("Filtered category is " + cat.category.name)
        const updatedList = data.filter(
            (x: any) => x.categories.includes(cat.category.seoFriendlyId)
        ); // TODO: change any to the proper type
        console.log("Updated List is " + updatedList)
        setFilter(updatedList);
        setCurrentCategory(cat.category.name);
    };



     // State to handle filtered products, assuming you have a function to fetch these
     const [filteredProducts, setFilteredProducts] = useState([]);

     // Function to handle filter application
     const applyFilter = (selectedCategories: string[]) => {
         // Example filter application logic

         // Check if the selectedCategories array is empty
        if (selectedCategories.length === 0) {
            filterAllProducts(data);
            return;  // Exit the function early
    }

        const updatedList = data.filter((product: any) => {
            return selectedCategories.some(category => product.categories.includes(category));
        });
        setFilter(updatedList);
        console.log('Filter applied with categories:', selectedCategories);
         // Assuming 'products' is your list of all products and you filter it based on 'selectedCategories'
     };

     



    const ShowProducts = () => {
        const unique_categories = data
        .map((item: any) => item.categories)
        .filter(
            (value, index, current_value) =>
                current_value.indexOf(value) === index
        )
        .sort();



        return (
            <>
                
                    <div className="d-flex justify-content-end scrollable-horizontal gap-3">
                        <FilterOverlay categories={categories} onApplyFilter={applyFilter} />
                        
                    </div>
                    <p>Showing {filter.length} of 7,000+ offers</p>
                <div ref={containerRef} className="stores-container">
                    {/* 
                    <div className="stores-filter-container">
                        <div className="d-flex flex-wrap justify-content-between mb-5">
                            <button
                                className={
                                    currentCategory !== "All"
                                        ? "btn btn-outline-dark me-2"
                                        : "btn btn-outline-dark btn-active me-2"
                                }
                                style={{ width:"100%" }}
                                onClick={() => filterAllProducts(data)}
                            >
                                All
                            </button>
                            


                            {categories.map((category: any ) => {
                                // TODO: change any to the proper type
                                const classes =
                                    currentCategory !== category.name
                                        ? "btn btn-outline-dark me-2"
                                        : "btn btn-outline-dark btn-active me-2";
                                return (
                                    <button
                                        className={classes}
                                        style={{ width:"100%" }}
                                        onClick={() => filterProduct({ category })}
                                    >
                                        {category.name}
                                    </button>
                                );
                            })}
                        </div>
                    </div> 
                                */}

                    <div className="store-cards">
                            {filter.map((product: any, index) => {

                            const isLastItem = index === filter.length - 1;
                                // TODO: change any to the proper type
                                return (
                                    <>
                                        <div
                                            className="col-md-3 mb-4"
                                            style={{ marginBottom: "1.5rem", padding:"1rem"}}
                                            
                                        >
                                            <div
                                                className="card h-100 text-center"
                                                ref={isLastItem ? lastItemRef : null} // Attach ref to the last item
                
                                                key={product.id}
                                            >
                                                <div className="card-header bg-transparent"
                                                style={{ border:"none",height:"7rem"}} >
                                                <NavLink
                                                    to={`/stores/${product.id}`}
                                                >
                                                    <img
                                                        src={
                                                            product.imageUrl
                                                        }
                                                        style={{ width: 'auto', maxWidth: '5rem', maxHeight: '5rem', objectFit: 'contain', marginTop:"2rem"}} 
                                                        className="card-img-top"
                                                        alt={product.name}
                                                    />
                                                </NavLink>
                                                </div>
                                                <div className="card-body">
                                                    <p className="card-title mb-0 ">
                                                        {product.name}
                                                    </p>
                                                    <p className="card-text lead">
                                                        {basisPoint !== null ? (
                                                                <p>{"Up to "}{Number((product.commission.value * parseInt(basisPoint)*.009).toFixed(1)) + product.commission.format}{" "}{"Cashback "}
                                                                </p>
                                                            ) : (
                                                                <p>Unavailable</p> // Or render null if you don't want to show anything
                                                            )
                                                        }
                                                    </p>
                                                    
                                                </div>
                                                <div className="card-footer">
                                                    <button
                                                        className="btn btn-outline-dark"
                                                        onClick={() => {
                                                            window.open(product.trackingUrl+ getUserExtId(), '_blank');
                                                        }}
                                                    >
                                                        Shop Now
                                                    </button>
                                                    <span>  </span>
                                                    <button
                                                        className="btn btn-outline-dark"
                                                        onClick={() => navigate(`/stores/${product.id}`)}
                                                    >
                                                        Details
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </>
                                );
                            })}
                    </div>
                    
                </div>
            </>
            
            
        );
    };

    return (
        <>
            <div className="container my-5">
                
                <div className="row">
                
                    <div className="col-12 mb-5">
                        <h1 className="display-6 fw-bolder text-center">
                            CashBack Offers on Your Favorite Stores
                        </h1>
                        
                        <hr />
                    </div>
                    <div className="d-flex justify-content-end scrollable-horizontal mb-3 gap-2 d-none" >
                    <FilterOverlay categories={categories} onApplyFilter={applyFilter} />
                    <input
                        type="text"
                        placeholder="Search stores, enter a category name..."
                        className="form-control"
                        value={searchTerm}
                        style={{ padding: '8px', paddingLeft: '30px' }}
                        onChange={handleSearchChange}
                    />
                    <div className="d-flex justify-content-end">
                        <button className="btn btn-outline-secondary" onClick={performSearch}>Search</button>
                        
                        {searchTerm && (
                            <button className="btn btn-outline-secondary" onClick={clearSearch}>Clear</button>

                        )}
                    </div>
                    
                </div>
                
                </div>
                
                <div className="row justify-content-center">
                    {loading ? <Loading /> : <ShowProducts />}
                </div>
            </div>
        </>
    );
}

/*<img src={require('./favorite_off.png')} width="40px" style={{marginLeft: "10px"}} onClick={() => {addFavorite(product);}}></img>*/
