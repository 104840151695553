import { useState , useEffect} from "react";

// return the user data from the session storage
export const getUser = () => {
  const userStr = sessionStorage.getItem('user');
  if (userStr) return JSON.parse(userStr);
  else return null;
}

// return the token from the session storage
export const getToken = () => {
  return sessionStorage.getItem('token') || null;
}

// set the token in the session storage
export const setToken = (token: any) => {
  sessionStorage.setItem('token', token);
}


// return the token from the session storage
export const getTokenCuckooWL = () => {
  return sessionStorage.getItem('tokenCuckooWL') || null;
}

// set the token in the session storage
export const setTokenCuckooWL = (token: any) => {
  sessionStorage.setItem('tokenCuckooWL', token);
}



// set the user in the session storage
export const setUser = (user: any) => {
  sessionStorage.setItem('user', JSON.stringify(user));
}

// set the token and user in the session storage
export const setUserSession = (token: any, user: any) => {
  sessionStorage.setItem('token', token);
  sessionStorage.setItem('user', JSON.stringify(user));
}

// remove the token and user from the session storage
export const removeUserSession = () => {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('user');
}





//*********************************************************/
// set the first name in the session storage
export const setFirstName = (name: any) => {
  sessionStorage.setItem('firstName', name);
}

// set the last name in the session storage
export const setLastName = (name: any) => {
  sessionStorage.setItem('lastName', name);
}

// set the points balance in the session storage
export const setPointBalance = (points: any, updatedTimestamp: any) => {


  const intPoints = parseInt(points);
  

  // Check if the conversion is successful and not NaN
  if (!isNaN(intPoints)) {
    // Store the int value in sessionStorage
    sessionStorage.setItem('pointBalance', intPoints.toString());
    sessionStorage.setItem('pointsUpdatedTimestamp', updatedTimestamp.toString());
  } else {
    // Handle the case where points couldn't be converted to a int
    console.error("Invalid points value. Couldn't convert to int.");
  }


}

// return the first name from the session storage
export const getFirstName = () => {
  return sessionStorage.getItem('firstName') || null;
}

// return the first name from the session storage
export const getLastName = () => {
  return sessionStorage.getItem('lastName') || null;
}

// return the first name from the session storage
export const getPointBalance = () => {
  return sessionStorage.getItem('pointBalance') || null;
}

export const getPointBalanceFormatted = () => {
  //return sessionStorage.getItem('pointBalance') || null;

  const storedValue = sessionStorage.getItem('pointBalance');
  return storedValue !== null ? parseInt(storedValue).toLocaleString() : null;
}

// return the first name from the session storage
export const getPointsUpdatedTimestamp = () => {
  const pointsUpdateDate = sessionStorage.getItem('pointsUpdatedTimestamp')?.slice(0, 10);
  
  return pointsUpdateDate || null;
}


export const getConversionRate = () => {
  return sessionStorage.getItem('conversionRate') || null;
}

export const getBasisPoint = () => {
  return sessionStorage.getItem('basisPoint') || null;
}


export const setConversionRate = (converstionRate: any) => {


  const floatConverstionRate = parseFloat(converstionRate);
  // Check if the conversion is successful and not NaN
  if (!isNaN(floatConverstionRate)) {
    // Store the int value in sessionStorage
    sessionStorage.setItem('conversionRate', floatConverstionRate.toString());
    console.info("in commons, the converstion rate is set as "+ floatConverstionRate.toString())
  } else {
    // Handle the case where points couldn't be converted to a int
    console.error("Invalid converstionRate value. Couldn't convert to int.");
  }


}

export const setBasisPoint = (basisPoint: any) => {

  const intBasisPoint = parseInt(basisPoint);
  

  // Check if the conversion is successful and not NaN
  if (!isNaN(intBasisPoint)) {
    // Store the int value in sessionStorage
    sessionStorage.setItem('basisPoint', intBasisPoint.toString());
  } else {
    // Handle the case where points couldn't be converted to a int
    console.error("Invalid basisPoint value. Couldn't convert to int.");
  }


}


//*********************************************************/
// set the user external id in the session storage
export const setUserExtId = (id: any) => {
  sessionStorage.setItem('userExtId', id);
}

// return the user external id from the session storage
export const getUserExtId = () => {
  return sessionStorage.getItem('userExtId') || null;
}


// set the user external id in the session storage
export const setProgramId = (id: any) => {
  sessionStorage.setItem('programId', id);
}

// return the user external id from the session storage
export const getProgramId = () => {
  return sessionStorage.getItem('programId') || null;
}



  
