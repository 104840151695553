import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you are using React Router for navigation
import Logo from './Header/Logo';
import FintechLogo from "../assets/FintechLogo.png";

const Footer: React.FC = () => {
  return (
    <footer className='footer'>
      <div className='footer-container' style={{display:"flex"}}>
        <div className='footer-logo'>
          <img src={FintechLogo} alt="Footer Logo" />
        </div>
        <div className='footer-items'style={{display:"flex"}}>
          <div className='footer-item'>
            <a className='footer-item' href="/">Home</a></div>
          <div className='footer-item'><a className='footer-item' href="/products">Gift Cards</a></div>
          <div className='footer-item'><a className='footer-item' href="/orderHistory">Order History</a></div>
          <div className='footer-item'><a className='footer-item' href="/cart">Cart</a></div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
