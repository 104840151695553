import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getToken, getTokenCuckooWL } from './common';
 
// handle the public routes
const PublicRoutes = () => {
  const tokenCuckooWL = getTokenCuckooWL();
  return !tokenCuckooWL ? <Outlet /> : <Navigate to="/home" />;
}
 
export default PublicRoutes;