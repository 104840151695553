import React from 'react';
import { Card, Table } from 'react-bootstrap';
import {
  getConversionRate,
  getFirstName,
  getLastName,
  getPointBalance,
  getPointBalanceFormatted,
  getPointsUpdatedTimestamp,
  getToken,
  getUser,
  removeUserSession,
} from "../utils/common";
import { Link } from 'react-router-dom';

interface ChildProps {
  conversionRate: number;
}

const RewardsAccountDetailCard: React.FC<ChildProps> = ({conversionRate}) => {

  const token = getToken();
  const firstName = getFirstName();
  const lastName = getLastName();
  const pointBalance = getPointBalanceFormatted();
  
  const pointBalanceNum = getPointBalance();
  const pointsUpdatedTimestamp = getPointsUpdatedTimestamp();

  const pointBalanceN = pointBalanceNum !== null ? parseInt(pointBalanceNum)*conversionRate : null;
  const dollarValue = pointBalanceN !== null ? pointBalanceN.toLocaleString() : null;
  
  

  return (
      <Card className="rewards-account-card">
        <div className="points-balance">
          <div className="points">
            <p className="points-text">Points Balance</p>
            <span className="balance" >{pointBalance}</span> points
          </div>
          <div className="equivalent-value">You points are worth ${dollarValue}*</div>
          <div className="as-of-date">*As on {pointsUpdatedTimestamp}</div>
        </div>
        <div className="member-details">
          <div className='member-info-left'> 
            <div className='heading'>Member since</div > 
            <div className='text'>11 Jun 2023</div>
          </div>
          <div className='member-info-right'>
            <div className='heading'>Earnings This Cycle</div > <div className='text'>2,560 points</div>
          </div>
        </div>
        <p className="additional-info">Recent Points Transactions</p>
        <div className='raTransactionHistory'>
          <Table responsive="sm" striped hover size="sm">
          <thead>
        <tr>
          <th>Transaction Date</th>
          <th>Details</th>
          <th>Amount</th>
          <th>Points Earned</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Jan 9, 2024</td>
          <td>SARAVANAA BHAVAN MISSISSAUGA, ON</td>
          <td>$50.63</td>
          <td>51 points</td>
        </tr>
        
      </tbody>
    </Table>
    </div>
        <div className="actions">  
          <a className="actions-buttons" href='/pointsHistory'>POINTS HISTORY</a>
          <a className="actions-buttons" href='/products'>EXPLORE REWARDS</a>
        </div>
      </Card>
  );
}

export default RewardsAccountDetailCard;
