// ChatbotIcon.tsx
import React from 'react';
import chatbotIcon from "../assets/FintechLogo.png"; // Adjust the import path as necessary

interface Props {
  onClick: () => void;
}

const ChatbotIcon: React.FC<Props> = ({ onClick }) => {
  return (
    <div className="chatbot-icon" onClick={onClick}>
      <img src={chatbotIcon} alt="Chatbot" style={{ width: 50, cursor: 'pointer' }} />
    </div>
  );
};

export default ChatbotIcon;
