import handleCart from "./handleCart";
import handleFavoriteList from "./handleFavoriteList";
import { combineReducers } from "redux";

const rootReducers = combineReducers({
  handleCart,
  handleFavoriteList,
});

export default rootReducers;
