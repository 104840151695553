import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface RewardCardProps {
  iconurl: string;
  title: string;
  description: string;
  buttonText: string;
  comingSoon?: boolean|string;
  link:string
}

const RewardCard: React.FC<RewardCardProps> = ({ iconurl, title, description, buttonText, comingSoon,link }) => {
  return (
    <div className="reward-card">
      <div className="icon">
        <img src={iconurl} alt={title} />
      </div>
      <div className='reward-card-details'>
        <div className="title">{title}</div>
        <div className="description">{description}</div>
      </div>
      {!comingSoon ? (
         <Link to={link}>
        <Button type="button" className="btn btn-primary btn-txt">
          {buttonText}
        </Button>
        </Link>
      ) : (
        <span className="coming-soon">COMING SOON!</span>
      )}
    </div>
  );
}

export default RewardCard;
