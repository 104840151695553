import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { RootState } from "../state/store";
import { bindActionCreators } from "redux";
import { actionCreators } from "../state";
import { getProgramId, getToken, getTokenCuckooWL, getUserExtId } from "../utils/common";
import parse from "html-react-parser";
import back from "../assets/back.png";
import { useSnackbar } from "notistack";
import { BACKEND_SERVER_BASE_URL } from "../App";
import "./Store.css";

export type ProductItemInfo = {
  utid: string;
  rewardName: string;
  faceValue: number;
  redemptionInstructions: string;
  currencyCode: string;
};

export type BrandItem = {
  brandKey: string;
  brandName: string;
  utid: string;
  rewardName: string;
  faceValue: number;
  currency: string;
  imageUrls: { [key: string]: string };
  qty: number;
  sub_total: number;
};

export default function Store() {
  const { id } = useParams<{ id: string }>();
  const [product, setProduct] = useState<any>([]); // TODO: update any to proper type
  const [loading, setLoading] = useState(true);
  const [amountIndex, setAmountIndex] = useState<number>(0);
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const { addCart } = bindActionCreators(actionCreators, dispatch);

  

  useEffect(() => {
    const getProduct = async () => {
      setLoading(true);
      const requestOptions: RequestInit = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            'x-program-id': `${getProgramId()}` ,
            Authorization: `Bearer ${getTokenCuckooWL()}`
        }
        //credentials: 'include' // Include cookies for cross-origin requests
      };

      // Define your parameters
      // Use the `id` from `useParams` as the query parameter
      const params = id ? new URLSearchParams({ ids: id }) : new URLSearchParams();

      console.log("Params for request is " +params)
      const response = await fetch(
          `${BACKEND_SERVER_BASE_URL}/api/v1/cashback/stores?${params.toString()}`,
          requestOptions
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      setProduct(await response.json());
      setLoading(false);
    };
    getProduct();
  }, []);

  const Loading = () => {
    return (
      <>
        <div className="col-md-6">
          <Skeleton height={400} />
        </div>
        <div className="col-md-6" style={{ lineHeight: 2 }}>
          <Skeleton height={50} width={300} />
          <Skeleton height={75} />
          <Skeleton height={25} width={150} />
          <Skeleton height={50} />
          <Skeleton height={150} />
          <Skeleton height={50} width={100} style={{ marginLeft: 6 }} />
        </div>
      </>
    );
  };
  const ShowProduct = () => {
    return (
      <>
        <NavLink
            to={`/stores`}
            style={{
              display: "flex",
              textDecoration: "none",
              marginBottom: "1rem",
            }}
          >
            <img
              src={back}
              className="btn-back"
              alt={product.name}
              style={{ height: "20px", width: "20px", margin: "auto 0" }}
            />
            <p
              style={{ margin: "auto", marginLeft: ".5rem", color: "#04366F" }}
            >
              Back
            </p>
          </NavLink>
        <div className="col-md-6" style={{ textAlign:"center"}}>
          <img
            src={
              product.content[0].imageUrl
            }
            alt={product.content[0].name}
            //height="400px"
            //width="400px"
            style={{marginBottom:"4rem", maxWidth:"15rem", textAlign:"center"}}
          />

          {product && product.content[0].storeTerms && (
            <div className="wrap-collabsible">
              <input id="collapsible3" className="toggle" type="checkbox" style={{display:"none"}}/>
              <label htmlFor="collapsible3" className="lbl-toggle">
                Specific Terms and Conditions for {parse(product.content[0].name)}
              </label>
              <div className="collapsible-content">
                <div className="content-inner">
                  <p>{parse(product.content[0].storeTerms)}</p>
                </div>
              </div>
            </div>
          )}
          {product &&
            (product.content[0].specificTerms) && (
              <div className="wrap-collabsible">
                <input id="collapsible2" className="toggle" type="checkbox" style={{display:"none"}}/>
                <label htmlFor="collapsible2" className="lbl-toggle">
                  Special Terms and Exclusions for {parse(product.content[0].name)}
                </label>
                <div className="collapsible-content">
                  <div className="content-inner">
                    <p>
                      {parse(product.content[0].specificTerms)}
                    </p>
                  </div>
                </div>
              </div>
            )}
          {product && product.content[0].generalTerms && (
            <div className="wrap-collabsible">
              <input id="collapsible1" className="toggle" type="checkbox" style={{display:"none"}}/>
              <label htmlFor="collapsible1" className="lbl-toggle">
                General Terms and Conditions for CashBack usage
              </label>
              <div className="collapsible-content">
                <div className="content-inner">
                  <p>{parse(product.content[0].generalTerms)}</p>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="col-md-6">
          
          <h4 className="text-uppercase text-black-50">{product.content[0].categories}</h4>
          <h1 className="display-5">{product.content[0].name}</h1>

          <button
            className="btn btn-dark ms-2 px-3 py-2"
            onClick={() => {
              window.open(product.content[0].trackingUrl + getUserExtId(), '_blank');
          }}
          >
            Get upto {Number((Number(product?.content[0]?.commission?.value) * .9).toFixed(1)) 
            }{ product?.content[0]?.commission?.format} at {product?.content[0]?.name}
          </button>

          
          

          {product && product.content[0].description && (
            <p className="lead" style={{ marginTop: "20px" }}>
              {parse(product.content[0].description)}
            </p>
          )}
        </div>
        
      </>
    );
  };

  return (
    <>
      <div className="store-container">
        <div className="row py-4">
          {loading ? <Loading /> : <ShowProduct />}
        </div>
      </div>
    </>
    
  );
}
