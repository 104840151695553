import { BrandItem } from "../../components/Product";
import { ActionType } from "../action-types";
import Action from "../actions";

const favoriteList: BrandItem[] = [];

const handleFavoriteList = (state = favoriteList, action: Action) => {
  switch (action.type) {
    case ActionType.ADD_FAVORITE_PRODUCT:
      const product: BrandItem = action.payload;
      return [
        ...state,
        {
          ...product,
        },
      ];

    default:
      return state;
  }
};

export default handleFavoriteList;
