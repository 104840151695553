import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { setUserSession } from "../utils/common";
import { BACKEND_SERVER_BASE_URL } from "../App";

export default function Login() {
  const history = useNavigate();
  const username: any = useFormInput("");
  const password: any = useFormInput("");
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  // handle button click of login form
  const handleLogin = () => {
    setError(null);
    setLoading(true);
    axios
      .post(
        `${BACKEND_SERVER_BASE_URL}/api/v1/gift-cards/authentication/login`,
        {
          email: username.value,
          password: password.value,
          programId: "client-a",
        }
      )
      .then((response) => {
        setLoading(false);
        const user = {
          firstName: response.data.userFirstName,
          lastName: response.data.userLastName,
          pointBalance: response.data.pointBalance,
        };
        setUserSession(response.data.token, user);
        history("/home");
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 401)
          setError(error.response.data.message);
        else setError("Something went wrong. Please try again later.");
      });
  };

  return (
    <>
      <div style={{ paddingTop: "5rem" }}>
        <h1 className="display-1 text-center m-4">Login</h1>
        <div className="container">
          <form className="row g-3 needs-validation" noValidate>
            <div className="mb-3">
              <label htmlFor="exampleInputUsername" className="form-label">
                Username
              </label>
              <input
                type="text"
                className="form-control"
                id="exampleInputUsername"
                aria-describedby="emailHelp"
                {...username}
              />
              <div id="emailHelp" className="form-text">
                We'll never share your email with anyone else.
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="exampleInputPassword" className="form-label">
                Password
              </label>
              <input
                type="password"
                className="form-control"
                id="exampleInputPassword"
                {...password}
              />
            </div>
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                Remember me
              </label>
            </div>
            <input
              type="button"
              className="btn btn-primary"
              value={loading ? "Loading..." : "Login"}
              onClick={handleLogin}
              disabled={loading}
            />
            <br />
          </form>
        </div>
      </div>
    </>
  );
}

const useFormInput = (initialValue: any) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e: any) => {
    setValue(e.target.value);
  };
  return {
    value,
    onChange: handleChange,
  };
};
