import React, { useEffect, useRef, ReactNode } from 'react';

// Define a type for your component's props
type ContentWrapperProps = {
  children: ReactNode; // This allows any valid React child
};

const ContentWrapper: React.FC<ContentWrapperProps> = ({ children }) => {
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const adjustPadding = () => {
      if (contentRef.current) {
        var navbarElement = document.querySelector('.navbar');
        
        const paddingTop = navbarElement ? parseInt(window.getComputedStyle(navbarElement).getPropertyValue('padding-top'), 10) : 0;
        const paddingBottom = navbarElement ? parseInt(window.getComputedStyle(navbarElement).getPropertyValue('padding-bottom'), 10) : 0;
        // Use optional chaining (?.) to safely access clientHeight
        const navbarHeight = navbarElement?.clientHeight ? navbarElement.clientHeight + paddingTop + paddingBottom :paddingTop + paddingBottom;
        console.log("nav bar height calculated as " + navbarHeight)
        if (navbarHeight) {
                  contentRef.current.style.marginTop = `${navbarHeight}px`;
                }
              }
            };

    adjustPadding();
    window.addEventListener('resize', adjustPadding);

    return () => window.removeEventListener('resize', adjustPadding);
  }, []);

  return <div ref={contentRef} style={{ display:"flex", flexDirection:"column", flexGrow:1 }}>{children}</div>;
};

export default ContentWrapper;
