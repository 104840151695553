import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import logo from "../assets/logo_light.png";
import FintechLogo from "../assets/FintechLogo.png";
import { RootState } from "../state/store";
import {
  getFirstName,
  getLastName,
  getPointBalance,
  getToken,
  getUser,
  removeUserSession,
  getPointBalanceFormatted
} from "../utils/common";
import Dropdown from "react-bootstrap/Dropdown";
import { BANK_FRONTEND_HOME_BASE_URL } from "../App";




export default function NavBar() {  

  const logoutURL = BANK_FRONTEND_HOME_BASE_URL + "/login"
  const state: any = useSelector((state: RootState) => state.handleCart); // TODO: change any to the proper type
  const history = useNavigate();

  // handle click event of logout button
  const handleLogout = () => {
    removeUserSession();
    history("/login");
  };

  // handle click event of Points History button
  const handlePointsHistory = () => {
    history("/pointsHistory");
  };

  // handle click event of Order History button
  const handleOrderHistory = () => {
    history("/orderHistory");
  };

  // Handle scroll behavior of the navbar
  const [lastScrollY, setLastScrollY] = useState(0);
  const [navbarVisible, setNavbarVisible] = useState(true);

  const controlNavbar = () => {
    if (typeof window !== 'undefined') {
      // Check scroll position
      if (window.scrollY > lastScrollY) {
        // If scrolling down, hide navbar
        setNavbarVisible(false);
      } else {
        // If scrolling up, show navbar
        setNavbarVisible(true);
      }
      // Update lastScrollY to current scroll position
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlNavbar);

      return () => {
        window.removeEventListener('scroll', controlNavbar);
      };
    }
  }, [lastScrollY]);


  
  
  const token = getToken();
  const firstName = getFirstName();
  const lastName = getLastName();
  const pointBalance = getPointBalanceFormatted();

  return (
    <nav
      className={`navbar fixed-top navbar-expand-lg navbar-light shadow-sm ${navbarVisible ? '' : 'hide-navbar'}`}
      
    >
      <div className="container">
        <NavLink className="navbar-brand" to="/">
          <img alt="Cuckoo" src={FintechLogo} width="150px"></img>
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo01"
          aria-controls="navbarTogglerDemo01"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
          <div className="navbar-nav" style={{ width: "100%"}}>
            <NavLink
                className="nav-link"
                to="/"
              >
                <div>Explore Rewards</div>
              </NavLink>
              
              <NavLink
                className="nav-link"
                to="/products"
              >
                <div>Gift Cards</div>
              </NavLink>

              <NavLink
                className="nav-link"
                to="/travel"
              >
                <div>Travel</div>
              </NavLink>
              <NavLink
                className="nav-link"
                to="/stores"
              >
                <div>Shop & Earn</div>
              </NavLink>

            <div
              className="nav-link buttons"
              style={{ marginLeft: "auto", display: "flex" }}
            >
              {token && (
                <div style={{ color: "#FFFFFF", alignSelf: "center" }}>
                  <div style={{ margin: "none", textTransform: "capitalize" }}>
                    {"Hi, " + firstName + "!"}
                  </div>
                  <div
                    style={{
                      margin: "none",
                      color: "#ffffff",
                      fontSize: "18px",
                    }}
                  >
                    <b>Points Balance: {pointBalance} pts</b>
                  </div>
                </div>
              )}
              {!token && (
                <NavLink to="/login" className="btn btn-outline-dark">
                  <i className="fa fa-sign-in me-1"></i> Login
                </NavLink>
              )}
              {!token && (
                <NavLink to="/register" className="btn btn-outline-dark ms-2">
                  <i className="fa fa-user-plus me-1"></i> Register
                </NavLink>
              )}
              {token && (
                <NavLink
                  to="/cart"
                  className="btn btn-outline-dark ms-2"
                  style={{ display: "flex", border: "none", margin: "auto" }}
                >
                  <i
                    className="fa fa-shopping-cart me-1"
                    style={{ fontSize: "1.5rem" }}
                  ></i>
                  {state.length > 0 && (
                    <div className="cart-tag">{state.length}</div>
                  )}
                </NavLink>
              )}
              {token && (
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    style={{
                      height: "100%",
                      border: "none",
                    }}
                  >
                    <i
                      className="fa fa-user-circle"
                      style={{
                        fontSize: "1.5rem",
                        borderRadius: "9999px",
                      }}
                    ></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={handleOrderHistory}>
                      Order History
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handlePointsHistory}>Points History</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      href={BANK_FRONTEND_HOME_BASE_URL}
                      target="_self"
                    >
                      Go to Account
                    </Dropdown.Item>
                    <Dropdown.Item 
                      onClick={handleLogout}
                      href={logoutURL}
                        target="_self"
                    >
                      Log Out
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
