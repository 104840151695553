import React from "react";
import orderconfirm from "../assets/order_confirm.png";
import { Navigate, useNavigate } from "react-router-dom";

export default function OrderConfirm() {
  const history = useNavigate();

  const handleContinueShopping = () => {
    history("/products");
  };

  return (
    <div
      className="container my-5"
      style={{ paddingTop: "10rem", textAlign: "center" }}
    >
      <div>
        <h2 style={{ margin: "auto 0", display: "inline-block" }}>
          <img
            alt="Order Successful"
            src={orderconfirm}
            style={{ height: "50px" }}
          />
          Thank you!
        </h2>
      </div>
      <h4 style={{ marginTop: 0, textAlign: "center" }}>
        Your order is place. You will receive your gift card in your email
        soon.
      </h4>
      <input
        type="button"
        className="btn btn-primary"
        value={"← Continue Shopping"}
        onClick={handleContinueShopping}
        style={{
          backgroundColor: "#B23B3F",
          borderColor: "#B23B3F",
          width: "20%",
          alignSelf: "center",
        }}
      />
      <h4 style={{ marginTop: 0, textAlign: "center" }}> 
      </h4>
      <input
        type="button"
        className="btn btn-primary"
        value={"Order History"}
        onClick={() => window.location.href='/orderHistory'}
        style={{
          backgroundColor: "#B23B3F",
          borderColor: "#B23B3F",
          width: "20%",
          alignSelf: "center",
        }}
      />
    </div>
  );
}
