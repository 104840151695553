import React from 'react';
// import EarnRewardsItem1Img from './assets/EarnRewards/earnrewardsItem1.png';
// import EarnRewardsItem2Img from '../assets/EarnRewards/earnrewardsItem2.png';
// import EarnRewardsItem3Img from '../assets/EarnRewards/earnrewardsItem3.png';
// import EarnRewardsItem4Img from '../assets/EarnRewards/earnrewardsItem4.png';
import EarnRewardInfoItem from './EarnRewardInfoItem';



const EarnReawrdsInfoCard:React.FC = () => {
  return (
    // <div  className='earn-rewards-card card'>
    <div className="earn-rewards-info cardss">
      <EarnRewardInfoItem
        bulletIconsrc={
          process.env.PUBLIC_URL + "./assets/EarnRewards/earnrewardsItem1.png"
        }
        text="Spend on travel and get 5 points for each $ spend"
      />
      <EarnRewardInfoItem
        bulletIconsrc={
          process.env.PUBLIC_URL + "./assets/EarnRewards/earnrewardsItem2.png"
        }
        text="Spend on grocery and earn 2 points for each $"
      />
      <EarnRewardInfoItem
        bulletIconsrc={
          process.env.PUBLIC_URL + "./assets/EarnRewards/earnrewardsItem3.png"
        }
        text="Complete rewards profile and earn 200 points"
      />
      <EarnRewardInfoItem
        bulletIconsrc={
          process.env.PUBLIC_URL + "./assets/EarnRewards/earnrewardsItem4.png"
        }
        text="Shop at Walmart between May 15th and 25th earn extra chocolate bar"
      />
    </div>
  );
}

export default EarnReawrdsInfoCard