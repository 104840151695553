import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import CartProduct from "./cart/CartProduct";
import { RootState } from "../state/store";
import axios from "axios";
import {
    getBasisPoint,
    getProgramId,
    getToken,
    getTokenCuckooWL,
    setFirstName,
    setLastName,
    setPointBalance,
    setUser,
    setUserSession,
} from "../utils/common";
import { Table } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { actionCreators } from "../state";
import { BACKEND_SERVER_BASE_URL } from "../App";

export default function Cart() {
    const state: any = useSelector((state: RootState) => state.handleCart); // TODO: change any to proper type

    const history = useNavigate();
    const [error, setError] = useState<any>(null);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const { clearCart } = bindActionCreators(actionCreators, dispatch);

    const basisPoint = getBasisPoint(); //get points multiplier

    //handle continue shopping
    const handleContinueShopping = () => {
        history("/products");
    };

    // handle button click of login form
    const handleFetchUser = (state: any) => {
        setError(null);
        setLoading(true);
        const requestOptions1 = {
            headers: {
                'x-program-id': `${getProgramId()}`  // Replace with your custom header and value
            },
            Authorization: `Bearer ${getTokenCuckooWL()}`
            //withCredentials: true
        };
        axios
            .get(
                `${BACKEND_SERVER_BASE_URL}/api/v1/programs/${getProgramId()}/users/bank-user-details`,
                requestOptions1
            )
            .then((response) => {
                setLoading(false);
                setFirstName(response.data.firstName);
                setLastName(response.data.lastName);
                setPointBalance(response.data.pointBalance, response.data.updatedTimestamp);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                if (error.response.status === 401)
                    setError(error.response.data.message);
                else setError("Something went wrong. Please try again later.");
            });
    };
    // handle button click of checkout form
    const handleCheckout = (state: any) => {
        setError(null);
        setLoading(true);
        const finalCart = state.map(function (element: any) {
            return {
                utid: element.utid,
                amount: element.faceValue,
                quantity: element.qty,
            };
        });
        const requestOptions = {
            headers: {
                'x-program-id': `${getProgramId()}` , // Replace with your custom header and value
                Authorization: `Bearer ${getTokenCuckooWL()}`
            },
            
        };
        axios
            .post(
                `${BACKEND_SERVER_BASE_URL}/api/v1/gift-cards/programs/${getProgramId()}/orders/${getToken()}`,
                { items: finalCart ,
                    tokenFromBank: getToken()},
                requestOptions
            )
            .then((response) => {
                //we don't need to fetch user details again, the updated points should be added in the order call if needed
                //handleFetchUser(state);
                clearCart();
                history("/orderconfirm");
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                if (error.response.status === 401)
                    setError(error.response.data.message);
                else setError("Something went wrong. Please try again later.");
            });
    };

    // // handle button click of login form
    // const handleFetchUser = (state: any) => {
    //     setError(null);
    //     setLoading(true);
    //     const auth = {
    //         headers: {
    //             Authorization: `Bearer ${getToken()}`,
    //         },
    //     };
    //     axios
    //         .get(
    //             `${BACKEND_SERVER_BASE_URL}/api/v1/gift-cards/programs/client-a/users/my-details`,
    //             auth
    //         )
    //         .then((response) => {
    //             setLoading(false);
    //             const user = {
    //                 firstName: response.data.firstName,
    //                 lastName: response.data.lastName,
    //                 pointBalance: response.data.pointBalance,
    //             };
    //             setUser(user);
    //             setLoading(false);
    //         })
    //         .catch((error) => {
    //             setLoading(false);
    //             if (error.response.status === 401)
    //                 setError(error.response.data.message);
    //             else setError("Something went wrong. Please try again later.");
    //         });
    // };

    let total: number = 0;
    return (
        <div
            className="cartContainer container"
            style={{ paddingTop: "10rem", flexGrow: 1 }}
        >
            <div className="row">
                <div
                    className="col-md-6 statusContainer"
                    style={{ paddingLeft: "1.5rem", width: "56%" }}
                >
                    {state.length === 0 && (
                        <h2 style={{ marginTop: "2rem", textAlign: "left" }}>
                            Your Shopping Cart is empty ...
                        </h2>
                    )}
                    {state.length !== 0 && (
                        <h2
                            className="title"
                            style={{ marginTop: "2rem", textAlign: "left" }}
                        >
                            Your Shopping Cart
                        </h2>
                    )}
                    <br />
                    {state?.map((cartProduct: any) => {
                        // TODO: change any to proper type
                        return <CartProduct cartProduct={cartProduct} />;
                    })}
                    <input
                        type="button"
                        className="btn btn-outline-dark"
                        value={
                            loading ? "Processing..." : "← Continue Shopping"
                        }
                        onClick={handleContinueShopping}
                        disabled={loading}
                    />
                    <br />
                </div>
                {state.length !== 0 && (
                    <div
                        className="col-md-6 statusContainer"
                        style={{
                            width: "40%",
                            marginLeft: "1.5rem",
                            paddingLeft: "1.5rem",
                            paddingRight: "1.5rem",
                        }}
                    >
                        <h2 style={{ marginTop: "2rem", textAlign: "left" }}>
                            Order Summary
                        </h2>{" "}
                        <br />
                        {state.map((x: any) => {
                            // TODO: change any to proper type
                            total += x.sub_total;
                        })}
                        <table
                            style={{
                                borderColor: "transparent",
                                marginBottom: "1rem",
                            }}
                        >
                            <thead>
                                <tr>
                                    <th style={{ textAlign: "left" }}>
                                        Sub Total
                                    </th>
                                    <td style={{ textAlign: "right" }}>
                                        {total * Number(basisPoint)} pts
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th style={{ textAlign: "left" }}>
                                        Promotional Code
                                    </th>
                                    <td style={{ textAlign: "right" }}>
                                        0 pts
                                    </td>
                                </tr>
                                <tr>
                                    <th style={{ textAlign: "left" }}>
                                        <hr></hr>
                                    </th>
                                    <td style={{ textAlign: "right" }}>
                                        <hr></hr>
                                    </td>
                                </tr>
                                <tr>
                                    <th
                                        style={{
                                            fontSize: "20px",
                                            textAlign: "left",
                                        }}
                                    >
                                        Your Total
                                    </th>
                                    <th
                                        style={{
                                            fontSize: "20px",
                                            textAlign: "right",
                                        }}
                                    >
                                        {total * Number(basisPoint)} pts
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                        <input
                            type="button"
                            className="btn btn-outline-dark"
                            value={loading ? "Processing..." : "Place Order"}
                            onClick={() => handleCheckout(state)}
                            disabled={loading}
                        />
                        <br />
                        <p style={{ color: "#565959", fontSize: "12px" }}>
                            By placing your order, you agree to Cuckoo's privacy
                            notice and conditions of use
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
}

/*<div className="total"> $ {total.toFixed(2)}</div>*/
