import React from 'react';
import Table from 'react-bootstrap/Table';

export default function PointsHistory() {
  return <div className="container my-5" style={{paddingTop:"2rem", textAlign:"center", flexGrow: 1}}>
    <h2 style={{ marginBottom: "2rem" }}>Points History</h2>
    <Table striped bordered hover size="sm">
      <thead>
        <tr>
          <th>Transaction Date</th>
          <th>Details</th>
          <th>Amount</th>
          <th>Points Earned</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>May 9, 2023</td>
          <td>SARAVANAA BHAVAN MISSISSAUGA, ON</td>
          <td>$50.63</td>
          <td>51</td>
        </tr>
        <tr>
          <td>May 9, 2023</td>
          <td>BEST HOMEOCARE INC BRAMPTON, ON</td>
          <td>$134.50</td>
          <td>134</td>
        </tr>
        <tr>
          <td>May 9, 2023</td>
          <td>AMAYA EXPRESS BRAMPTON, ON</td>
          <td>$18.70</td>
          <td>19</td>
        </tr>
      </tbody>
    </Table>
  </div>
}