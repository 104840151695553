import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './GCFilterOverlay.css';

interface Category {
    name: string;
}

interface FilterOverlayProps {
    categories: string[]; // Adjust the type to match expected data structure
    onApplyFilter: (selectedCategories: string[]) => void;
    clearFilters: () => void;
}

const GCFilterOverlay: React.FC<FilterOverlayProps> = ({ categories, onApplyFilter, clearFilters }) => {
    const [show, setShow] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);


    useEffect(() => {
        // Load selected categories from local storage when the component mounts
        const savedCategories = localStorage.getItem('selectedCategories');
        if (savedCategories) {
            setSelectedCategories(JSON.parse(savedCategories));
        }
    }, []);

    useEffect(() => {
        // Save selected categories to local storage whenever they change
        localStorage.setItem('selectedCategories', JSON.stringify(selectedCategories));
    }, [selectedCategories]);

    

    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleFilterChange = (category: string, isChecked: boolean) => {
        setSelectedCategories(prev => 
            isChecked ? [...prev, category] : prev.filter(id => id !== category)
        );
    };
    const handleApply = () => {
        onApplyFilter(selectedCategories);
        handleClose();
    };

    const handleClear = () => {
        setSelectedCategories([]);
        clearFilters();
    };


    return (
        <>
            <button className="btn btn-dark" onClick={handleShow}>
                Filters
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="modal-dialog" contentClassName="filter-modal-content">
                <Modal.Header closeButton>
                    <Modal.Title>Filter Gift Cards</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="container">
                    <div className="row">
                        {categories.map((category, index) => (
                            <div key={index} className="col-12 col-sm-6 col-md-3 mb-4"> {/* Ensuring responsiveness across different sizes */}
                                <div className="form-check">
                                    <input 
                                        className="form-check-input" 
                                        type="checkbox" 
                                        id={category} 
                                        value={category} 
                                        onChange={(e) => handleFilterChange(category, e.target.checked)} 
                                        checked={selectedCategories.includes(category)}
                                    />
                                    <label className="form-check-label" htmlFor={category}>
                                        {category}
                                    </label>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <button className="btn btn-outline-dark" onClick={handleClear}>Clear Filters</button>
                    <button className="btn btn-outline-dark" onClick={handleApply}>
                        Apply Filters
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default GCFilterOverlay;
