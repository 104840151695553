import React from 'react';

interface InfoCardProps {
  iconText: string;
  title1: string;
  title2: string;
  description: string;
}

const InfoCard: React.FC<InfoCardProps> = ({ iconText, title1, title2, description }) => {
  return (
    <div className="info-card">
      <div className="icon-circle"><div className="icon-circle-text">{iconText}</div></div> 
      <div className="info-title">
        <div className="info-title-main">{title1}</div>
        <div className="info-title-sub">{title2}</div>
      </div>
      <p className="info-description">{description}</p>
    </div>
  );
}

export default InfoCard;
