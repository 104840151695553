import React, { useEffect, useState } from "react";

import NavBar from "./components/NavBar";
import Home from "./components/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Products from "./components/Products";
import Product from "./components/Product";
import Cart from "./components/Cart";
import Login from "./components/Login";
import Register from "./components/Register";
import NotFound from "./components/NotFound";
import PublicRoutes from "./utils/PublicRoutes";
import PrivateRoutes from "./utils/PrivateRoutes";
import OrderConfirm from "./components/OrderConfirm";
import PointsHistory from "./components/PointsHistory";
import OrderHistory from "./components/OrderHistory";
import Carousel from "./components/CarouselHome";
import Footer from "./components/Footer";

import ContentWrapper from './components/ContentWrapper';

//Travel
//import TravelHome from "./components/travel/pages/Homepage";
//import HotelSearch from "./components/travel/pages/SearchPageResult";
//import TravelHomeTest from "./components/travel/pages/HomepageTest";
//import HotelSearchPage from "./components/travel/pages/HotelSearchPage";



import axios from "axios";
import { useSearchParams } from "react-router-dom";
import "./global.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css";
import './styles/styles.scss';



import {
    getToken,
    setToken,
    setFirstName,
    setTokenCuckooWL,
    getTokenCuckooWL,
    setLastName,
    setPointBalance,
    setUser,
    setUserSession,
    setConversionRate,
    setBasisPoint,
    setUserExtId,
    setProgramId,
    getProgramId


} from "../src/utils/common";
import Store from "./components/Store";
import Stores from "./components/Stores";
import ChatbotIcon from "./components/ChatbotIcon";
import ChatbotUI from "./components/ChatbotUI";




export const BACKEND_SERVER_BASE_URL =
  process.env.REACT_APP_BACKEND_SERVER_BASE_URL;
export const BANK_FRONTEND_HOME_BASE_URL =
  process.env.REACT_APP_BANK_FRONTEND_HOME_BASE_URL;
export const BANK_DOMAIN_URL =
  process.env.REACT_APP_BANK_DOMAIN_URL;

  export const CASHBACK_AUTH =
  process.env.REACT_APP_CASHBACK_AUTH;

function App() {

  //CHATBOT

  const [showChat, setShowChat] = useState(false);
  const toggleChat = () => setShowChat(!showChat);


  const [loadedUser, setLoadedUser] = useState(false);
  const [searchParams] = useSearchParams();
  for (const entry of searchParams.entries()) {
    const [param, value] = entry;
    if (param == "token") {
      setToken(value);
      
    } 
  }
  document.cookie = `rewards_session_id=MKe37Jk+KpIgqRKkJ+4iydADyKEMuQJzoOel7UCVFKY0kuLGzQ1yZYM21dbmB+UBzC+/Ex34QLRVCtBqI1VH/CM8l9rf03BiiZKYWotu/jO6m6HAuPuQBHoHwBNQeNi1jJTAl0O/A69Cr/I4aEmULYkU4f45/asVlmpFYF9KOMzekDGnZ3zyaWAIhKdwTNcA7BdWN3fbzx/cpiUUPVhjdssGiVlLkUwAYwzpwjCJn+fBSt3OneTwA9UiQmDkCbyLSathBDEX3L+89JR+YcyjEiKs3kH3ZXvqrnDGNTMRo8oHnHkAy0ve/yfj5Na2+AlVPH8rRknn4PBJfKSmVks4Sg==; Path=/; Expires=Sat, 25 Jan 2025 03:05:04 GMT;`;

  // this code is to pull the points from the bank and show latest points.

  useEffect(() => {
    const auth = {
      headers: {
          Authorization: `Bearer ${getToken()}`,
      },
    };

    const config = {
      headers: {
          'x-program-id': `${getProgramId()}`,// Replace with your custom header and value
          Authorization: `Bearer ${getToken()}`,
      },
      withCredentials: true // Ensures cookies are sent with the request
    };
  axios
      .get(
          `${BACKEND_SERVER_BASE_URL}/api/v1/gift-cards/programs/d4605712-41cc-4183-add7-374fa49344ff/users/bank-user-details`,
          auth
      )
      .then((response) => {
          setUserExtId(response.data.externalId);
          setFirstName(response.data.firstName);
          setLastName(response.data.lastName);
          setPointBalance(response.data.pointBalance, response.data.updatedTimestamp);
          setToken(response.data.tokenFromBank)
          setTokenCuckooWL(response.data.token)
          setProgramId(response.data.programId)
          setLoadedUser(true);
          
      })
      .catch((error) => {
          if (error.response.status === 401)
          console.log(error.message);
          else console.log(error.message);;
      });


  }, []);


  useEffect(() => {

    if (!loadedUser) {
      // If the first effect hasn't finished, don't do anything.
      return;
    }

    const auth1 = {
      headers: {
        "Content-Type": "application/json",
        'x-program-id': `${getProgramId()}` ,
        Authorization: `Bearer ${getTokenCuckooWL()}`
      }
    };
    axios
        .get(
            `${BACKEND_SERVER_BASE_URL}/api/v1/programs/${getProgramId()}`,
            auth1
        )
        .then((response) => {
          setConversionRate(response.data.defaultConversionRate);
          setBasisPoint(response.data.defaultBasisPoints);
          //setLoadedProgram(true);
          console.info("in home axios call conversion rate loaded as " + response.data.defaultConversionRate)
            
        })
        .catch((error) => {
            if (error.response.status === 401)
            console.log(error.message);
            else console.log(error.message);;
        });


  }, [loadedUser]);

  if (!loadedUser) {
    return <div className="d-flex justify-content-center">
    <div className="spinner-border" style={{ marginTop:'5rem', width: '5rem', height: '5rem' }} role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>;
  }


  
  return (
    <>


      {loadedUser && <NavBar />}
      <ContentWrapper>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route element={<PublicRoutes />}>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route path="/home" element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="/Carousel" element={<Carousel />} />
          <Route path="/products" element={<Products />} />
          <Route path="/products/:id" element={<Product />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/orderconfirm" element={<OrderConfirm />} />
          <Route path="/pointsHistory" element={<PointsHistory />} />
          <Route path="/orderHistory" element={<OrderHistory />} />
          
          <Route path="/stores" element={<Stores />} />
          <Route path="/stores/:id" element={<Store />} />
        </Route>
      </Routes>
      <ChatbotIcon onClick={toggleChat} />
      {showChat && <ChatbotUI />}
      <Footer />
      </ContentWrapper>
      
    </>
  );
}

export default App;

/*<Route path="/"  element={<Home/>} />


<Route path="/travel" element={<TravelHome />} />
          <Route path="/travelTest" element={<TravelHomeTest />} />
          <Route path="/list-hotels" element={<HotelSearch />} />
          <Route path="/hotels" element={<HotelSearchPage />} />

*/
