import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getTokenCuckooWL } from './common';
 
// handle the private routes
const PrivateRoutes = () => {
  const tokenCuckooWL = getTokenCuckooWL();
  return tokenCuckooWL ? <Outlet /> : <Navigate to="/login" />
}
 
export default PrivateRoutes;