import { BrandItem } from "../../components/Product";
import { ActionType } from "../action-types";
import Action from "../actions";

const cartInitialState: any = []; // TODO: change any to proper type

const handleCart = (state = cartInitialState, action: Action) => {
  switch (action.type) {
    case ActionType.ADDITEM:
      const productToAdd: BrandItem = action.payload;
      // Check product is exists
      const exist = state.find(
        (x: any) =>
          x.utid === productToAdd.utid && x.faceValue === productToAdd.faceValue // TODO: change any to the proper type
      );
      if (exist) {
        // Increase Quantity
        return state.map(
          (
            x: any // TODO: change any to the proper type
          ) =>
            x.utid === productToAdd.utid &&
            x.faceValue === productToAdd.faceValue
              ? { ...x, qty: x.qty + 1, sub_total: (x.qty + 1) * x.faceValue }
              : x
        );
      }

      return [
        ...state,
        {
          ...productToAdd,
          qty: 1,
          sub_total: productToAdd.faceValue,
        },
      ];

    case ActionType.DELITEM:
      const productToDel: BrandItem = action.payload;
      const exist1: any = state.find(
        // TODO: change any to the proper type
        (x: any) =>
          x.utid === productToDel.utid && x.faceValue === productToDel.faceValue // TODO: change any to the proper type
      );
      if (exist1.qty === 1) {
        return state.filter(
          (
            x: any // TODO: change any to the proper type
          ) => x.utid !== exist1.utid || x.faceValue !== productToDel.faceValue
        );
      } else {
        return state.map(
          (
            x: any // TODO: change any to the proper type
          ) =>
            x.utid === productToDel.utid &&
            x.faceValue === productToDel.faceValue
              ? { ...x, qty: x.qty - 1, sub_total: (x.qty - 1) * x.faceValue }
              : x
        );
      }

    case ActionType.RMVITEM:
      const productToRmv: BrandItem = action.payload;
      const exist2: any = state.find(
        // TODO: change any to the proper type
        (x: any) =>
          x.utid === productToRmv.utid && x.faceValue === productToRmv.faceValue // TODO: change any to the proper type
      );
      if (exist2.qty) {
        return state.filter(
          (
            x: any // TODO: change any to the proper type
          ) => x.utid !== exist2.utid || x.faceValue !== productToRmv.faceValue
        );
      }
      break;

    case ActionType.CLEARCART:
      return cartInitialState;
    default:
      return state;
  }
};

export default handleCart;
