import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../state";
import { BrandItem } from "./Product";
import { getToken, getTokenCuckooWL, getBasisPoint, getProgramId } from "../utils/common";
import Button from "react-bootstrap/Button";
import { useSnackbar } from "notistack";
import { BACKEND_SERVER_BASE_URL } from "../App";
import FilterOverlay from "./FilterOverlay";
import GCFilterOverlay from "./GCFilterOverlay";


export default function Products() {
    const [data, setData] = useState([]);
    const [countGC, setCountGC] = useState(197);
    const [filter, setFilter] = useState<any[]>(data);
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState<string[]>([]);

    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [currentCategory, setCurrentCategory] = useState("All");
    var unique_categories: any[]=[];
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { addCart } = bindActionCreators(actionCreators, dispatch);
    const basisPoint = getBasisPoint();

    let componentMounted = true;

    const addProduct = (product: any) => {
        // TODO: change any to proper type
        const cartProduct: BrandItem = {
            brandKey: product.brandKey,
            brandName: product.brandName,
            utid: product.utid,
            rewardName: product.rewardName,
            faceValue: product.defaultFaceValue,
            currency: product.currencyCode,
            imageUrls: product.imageUrls,
            qty: 1,
            sub_total: 0,
        };
        addCart(cartProduct);
        enqueueSnackbar(`Item added to your cart successfully`, {
            variant: "success",
            autoHideDuration: 3000,
        });
    };

    useEffect(() => {
        const getProducts = async () => {
            setLoading(true);
            const requestOptions: RequestInit = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    'x-program-id': `${getProgramId()}` ,
                    Authorization: `Bearer ${getTokenCuckooWL()}`
                }
                //credentials: 'include' // Include cookies for cross-origin requests
            };
            const response = await fetch(
                `${BACKEND_SERVER_BASE_URL}/api/v1/gift-cards/brands/items`,
                requestOptions
            );
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
     
            
            const extractUniqueCategories = (data: any[]): string[] => {
                const allCategories = data.flatMap(item => item.category);
                const uniqueCategories = Array.from(new Set(allCategories));
                return uniqueCategories.sort();
            };

        
            


            
            const brandItemsList = await response.json();
            setData(brandItemsList);
            setFilter(brandItemsList);
            setCurrentCategory("All");
            setCountGC(brandItemsList.length)
            unique_categories = extractUniqueCategories(brandItemsList);
            console.log("Total count of gift cards: " + brandItemsList.length + " Unique Categories: " + unique_categories);
            setCategories(extractUniqueCategories(brandItemsList));
            setLoading(false);
            
            
        };

        if (componentMounted) {
            getProducts();
        }

        return () => {
            componentMounted = false;
        };
    }, []);

    const Loading = () => {
        return (
            <>
                <div className="col-md-3">
                    <Skeleton height={350} />
                </div>

                <div className="col-md-3">
                    <Skeleton height={350} />
                </div>

                <div className="col-md-3">
                    <Skeleton height={350} />
                </div>
            </>
        );
    };
    const filterAllProducts = (data: any) => {
        setFilter(data);
        setCurrentCategory("All");
    };

    const filterProduct = (cat: any) => {
        // TODO: change any to proper type
        const updatedList = data.filter(
            (x: any) => x.category === cat.category
        ); // TODO: change any to the proper type

        setFilter(updatedList);
        setCurrentCategory(cat.category);
    };



    
    
    const ShowProducts = () => {
        
        

        return (
            <>  
                
                <div className="d-flex justify-content-end scrollable-horizontal">
                    <GCFilterOverlay categories={categories} onApplyFilter={applyFilter} clearFilters={clearFilters}/>
                    {currentCategory !== "All" && (<button
                        className={"btn btn-outline-dark ms-3"
                        }
                        onClick={() => filterAllProducts(data)}
                    >
                        Clear Filters
                    </button>)}

                </div>
                <p>
                    Showing {filter.length}  of total {countGC} gift cards
                </p>
                {filter.map((product: any) => {
                    // TODO: change any to the proper type
                    return (
                        <>
                            <div
                                className="col-md-3 mb-4"
                                style={{ marginTop: "1.5rem" }}
                            >
                                <div
                                    className="card h-100 text-center"
                                    key={product.brandKey}
                                    style={{ border: "none" }}
                                >
                                    <NavLink
                                        to={`/products/${product.brandKey}`}
                                    >
                                        <img
                                            src={
                                                product.imageUrls[
                                                    "1200w-326ppi"
                                                ]
                                            }
                                            //height="250px"
                                            className="card-img-top"
                                            alt={product.brandName}
                                        />
                                    </NavLink>
                                    <div className="card-body">
                                        <h5 className="card-title mb-0 fw-bold">
                                            {product.brandName}
                                        </h5>
                                        <p className="card-text lead">
                                            {basisPoint !== null ? (
                                                    <p>{product.defaultFaceValue * parseInt(basisPoint)+ "pts"}{" "}
                                                    / $ {product.defaultFaceValue}</p>
                                                ) : (
                                                    <p>Unavailable</p> // Or render null if you don't want to show anything
                                                )
                                            }
                                        </p>
                                        <button
                                            className="btn btn-outline-dark"
                                            onClick={() => {
                                                addProduct(product);
                                            }}
                                        >
                                            Add to cart
                                        </button>
                                    </div>
                                </div>
                            </div>
                            
                        </>
                    );
                })}
            </>
            
            
        );
    };

    const applyFilter = (selectedCategories: string[]) => {
        // Example filter application logic
        
        // Check if the selectedCategories array is empty
       if (selectedCategories.length === 0) {
           filterAllProducts(data);
           return;  // Exit the function early
        }

       const updatedList = data.filter((product: any) => {
           return selectedCategories.some(category => product.category.includes(category));
       });
       setFilter(updatedList);
       setCurrentCategory("None");
       console.log('Filter applied with categories:', selectedCategories);
        // Assuming 'products' is your list of all products and you filter it based on 'selectedCategories'
    };

    const clearFilters = () => {
        // Clear the filters and reset data
        localStorage.removeItem('selectedCategories');
        setFilter(data);
    };

    return (
        <>
            <div className="container my-5">
                <div className="row">
                    <div className="col-12 mb-5">
                        <h1 className="display-6 fw-bolder text-center">
                            Gift Cards
                        </h1>
                        <hr />
                    </div>
                </div>
                
                <div className="row justify-content-center">
                    {loading ? <Loading /> : <ShowProducts />}
                </div>
                <div className="wrap-collabsible">
                    <input id="collapsible3" className="toggle" type="checkbox" style={{display:"none"}}/>
                    <label htmlFor="collapsible3" className="lbl-toggle">
                        General Merchant Disclaimer
                    </label>
                    <div className="collapsible-content">
                        <div className="content-inner">
                        <p>The merchants represented are not sponsors of the rewards or otherwise affiliated with Tango Card, Inc. The logos and other identifying marks attached are trademarks of and owned by each represented company and/or its affiliates. Please visit each company’s website for additional terms and conditions.</p>
                        </div>
                    </div>
                    </div>
            </div>
        </>
    );
}

/*<img src={require('./favorite_off.png')} width="40px" style={{marginLeft: "10px"}} onClick={() => {addFavorite(product);}}></img>*/
