import React from 'react';
import { Button, Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import '../styles/styles.scss';

const CarouselHome: React.FC = () => {
  return (
    <Carousel>
      <Carousel.Item>
      <div className="carousel-image">
        <img
          className="d-block w-100"
          src={process.env.PUBLIC_URL+'./assets/giftcards.png'}
          alt="First slide"
        />
        <div className="carousel-overlay"></div>
      </div>
        <Carousel.Caption>
          <div className="carousel-content-container">
            <div className="carousel-text-container">
              <h3>Gift Cards</h3>
              <p>Gift Cards are the best way to celeberate this season</p>
            </div>
            <div className="carousel-button-container">
              <Link to={process.env.PUBLIC_URL+ "/products"}>
                <Button className="carouselbutton" type="button">
              Shop Gift Cards
            </Button>
            </Link>
            </div>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <div className="carousel-image">
        <img
          className="d-block w-100"
          src={process.env.PUBLIC_URL+'./assets/home-hero-travel.jpg'}
          alt="Second slide"
        />
        <div className="carousel-overlay"></div>
      </div>
        <Carousel.Caption>
        <div className="carousel-content-container">
        <div className="carousel-text-container">
        <h3>Travel</h3>
          <p>Find the best get away today with amazing travel options</p>
          </div>
           <div className="carousel-button-container">
          <Link to={process.env.PUBLIC_URL+ "/travel"}>
            <Button className="carouselbutton" type="button">
          Explore Travel
        </Button>
        </Link>
        </div>
        </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <div className="carousel-image">
        <img
          className="d-block w-100"
          src={process.env.PUBLIC_URL+'./assets/home-hero-offers.jpg'}
          alt="Third slide"
        />
        <div className="carousel-overlay"></div>
      </div>
        <Carousel.Caption>
        <div className="carousel-content-container">
        <div className="carousel-text-container">
        <h3>Cashback</h3>
          <p>Shop in style with cashback offers designed for you</p>
          </div>
          <div className="carousel-button-container">
          <Link to={process.env.PUBLIC_URL+ "/stores"}>
            <Button className="carouselbutton" type="button">
          Cashback Offers
        </Button>
        </Link>
        </div>
        
        </div>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default CarouselHome;