import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { RootState } from "../state/store";
import { bindActionCreators } from "redux";
import { actionCreators } from "../state";
import { getProgramId, getToken, getTokenCuckooWL } from "../utils/common";
import parse from "html-react-parser";
import back from "../assets/back.png";
import { useSnackbar } from "notistack";
import { BACKEND_SERVER_BASE_URL } from "../App";
import Footer from "./Footer";

export type ProductItemInfo = {
  utid: string;
  rewardName: string;
  faceValue: number;
  redemptionInstructions: string;
  currencyCode: string;
};

export type BrandItem = {
  brandKey: string;
  brandName: string;
  utid: string;
  rewardName: string;
  faceValue: number;
  currency: string;
  imageUrls: { [key: string]: string };
  qty: number;
  sub_total: number;
};

export default function Product() {
  const { id } = useParams<{ id: string }>();
  const [product, setProduct] = useState<any>([]); // TODO: update any to proper type
  const [loading, setLoading] = useState(true);
  const [amountIndex, setAmountIndex] = useState<number>(0);
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const { addCart } = bindActionCreators(actionCreators, dispatch);

  const addProduct = (product: any) => {
    // TODO: update any to proper type
    const cartProduct: BrandItem = {
      brandKey: product.brandKey,
      brandName: product.brandName,
      utid: product.items[amountIndex].utid,
      rewardName: product.items[amountIndex].rewardName,
      faceValue: product.items[amountIndex].faceValue,
      currency: product.items[amountIndex].currencyCode,
      imageUrls: product.imageUrls,
      qty: 1,
      sub_total: 0,
    };
    addCart(cartProduct);
    enqueueSnackbar(`Item added to your cart successfully`, {
      variant: "success",
      autoHideDuration: 3000,
    });
  };

  const setCartProduct = (index: number) => {
    setAmountIndex(index);
  };

  useEffect(() => {
    const getProduct = async () => {
      setLoading(true);
      const requestOptions: RequestInit = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            'x-program-id': `${getProgramId()}` ,
            Authorization: `Bearer ${getTokenCuckooWL()}`
        }
        // credentials: 'include' // Include cookies for cross-origin requests
      };
      const response = await fetch(
        `${BACKEND_SERVER_BASE_URL}/api/v1/gift-cards/brands/${id}/items`,
        requestOptions
      );
      setProduct(await response.json());
      setLoading(false);
    };
    getProduct();
  }, []);

  const Loading = () => {
    return (
      <>
        <div className="col-md-6">
          <Skeleton height={400} />
        </div>
        <div className="col-md-6" style={{ lineHeight: 2 }}>
          <Skeleton height={50} width={300} />
          <Skeleton height={75} />
          <Skeleton height={25} width={150} />
          <Skeleton height={50} />
          <Skeleton height={150} />
          <Skeleton height={50} width={100} style={{ marginLeft: 6 }} />
        </div>
      </>
    );
  };
  const ShowProduct = () => {
    return (
      <>
        <div className="col-md-6">
          <img
            src={
              product.imageUrls
                ? product.imageUrls["1200w-326ppi"]
                : product.image
            }
            alt={product.brandName}
            //height="400px"
            width="400px"
          />

          {product && product.items && (
            <div className="wrap-collabsible">
              <input id="collapsible3" className="toggle" type="checkbox" style={{display:"none"}}/>
              <label htmlFor="collapsible3" className="lbl-toggle">
                Redemption Instructions
              </label>
              <div className="collapsible-content">
                <div className="content-inner">
                  <p>{parse(product.items[0].redemptionInstructions)}</p>
                </div>
              </div>
            </div>
          )}
          {product &&
            (product.terms || product.termsAndConditionsInstructions) && (
              <div className="wrap-collabsible">
                <input id="collapsible2" className="toggle" type="checkbox" style={{display:"none"}}/>
                <label htmlFor="collapsible2" className="lbl-toggle">
                  Terms & Conditions
                </label>
                <div className="collapsible-content">
                  <div className="content-inner">
                    <p>
                      {parse(product.terms)}
                      {parse(product.termsAndConditionsInstructions)}
                    </p>
                  </div>
                </div>
              </div>
            )}
          {product && product.disclaimer && (
            <div className="wrap-collabsible">
              <input id="collapsible1" className="toggle" type="checkbox" style={{display:"none"}}/>
              <label htmlFor="collapsible1" className="lbl-toggle">
                Disclaimer
              </label>
              <div className="collapsible-content">
                <div className="content-inner">
                  <p>{parse(product.disclaimer)}</p>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="col-md-6">
          <NavLink
            to={`/products`}
            style={{
              display: "flex",
              textDecoration: "none",
              marginBottom: "1rem",
            }}
          >
            <img
              src={back}
              className="btn-back"
              alt={product.brandName}
              style={{ height: "20px", width: "20px", margin: "auto 0" }}
            />
            <p
              style={{ margin: "auto", marginLeft: ".5rem", color: "#04366F" }}
            >
              Back
            </p>
          </NavLink>
          <h4 className="text-uppercase text-black-50">{product.category}</h4>
          <h1 className="display-5">{product.brandName + " Gift Card"}</h1>

          <label>Choose a gift card amount:</label>
          <div style={{ display: "flex" }}>
            {product &&
              product.items &&
              product.items.map((item: ProductItemInfo, index: number) => {
                return (
                  <div
                    className="col-md-3 mb-4"
                    style={{ width: "fit-content", paddingRight: "10px" }}
                  >
                    <button
                      id={index.toString()}
                      name="gift-card-amount"
                      value={item.faceValue}
                      className={
                        amountIndex === index
                          ? "gift-card-amount-active"
                          : "gift-card-amount"
                      }
                      onClick={() => {
                        setCartProduct(index);
                      }}
                    >
                      $ {item.faceValue}
                    </button>
                  </div>
                );
              })}
          </div>

          <NavLink to="/cart" className="btn btn-outline-dark px-4 py-2">
            Go to cart
          </NavLink>
          <button
            className="btn btn-dark ms-2 px-3 py-2"
            onClick={() => {
              addProduct(product);
            }}
          >
            Add to cart
          </button>

          {product && product.description && (
            <p className="lead" style={{ marginTop: "20px" }}>
              {parse(product.description)}
            </p>
          )}
        </div>
        
      </>
    );
  };

  return (
    <>
      <div className="container" style={{ paddingTop: "10rem" }}>
        <div className="row py-4">
          {loading ? <Loading /> : <ShowProduct />}
        </div>
      </div>
    </>
    
  );
}
