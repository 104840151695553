import React from 'react';

interface EarnRewardInfoItemProps {
  bulletIconsrc: string;
  text: string;
}

const EarnRewardInfoItem: React.FC<EarnRewardInfoItemProps> = ({ bulletIconsrc, text }) => {
  return (
    <div className='earn-reward-item'>
      <div className='earn-reward-item-bulletIcon'>
        <img src={bulletIconsrc} alt="Bullet Icon" />
      </div>
      <div className='earn-reward-item-text'>
        {text}
      </div>
    </div>
  );
}

export default EarnRewardInfoItem;
