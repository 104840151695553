import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router-dom";
import { BACKEND_SERVER_BASE_URL } from "../App";
import axios from "axios";
import Footer from "./Footer";

import {
  getProgramId,
  getToken,
  getTokenCuckooWL,
  getUserExtId,
} from "../utils/common";

export default function OrderHistory() {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState<any[]>(data);
  const [loading, setLoading] = useState(false);
  const history = useNavigate();
  let componentMounted = true;

  const handleContinueShopping = () => {
    history("/products");
  };

  useEffect(() => {
    const getProducts = async () => {
      setLoading(true);
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getTokenCuckooWL()}`
        },
      };
      axios.get(
        `${BACKEND_SERVER_BASE_URL}/api/v1/gift-cards/programs/${getProgramId()}/orders/${getUserExtId()}`,
        requestOptions
      ).then(response => {
        setData(response.data);
        setFilter(response.data);
        componentMounted = false;
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      })
      .finally(() => {
        setLoading(false);
      });
      
    };
    getProducts();
  }, []);

  return (
    <>
    {loading && <p style={{ paddingTop: "10rem"}}>Loading...</p>}
    {!loading && data && data.length > 0 ? (
    <div
      className="container my-5"
      style={{ paddingTop: "0rem", textAlign: "center", flexGrow: 1 }}
    >
      <h2 style={{ marginBottom: "2rem" }}>Order History</h2>

      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Transaction Date</th>
            <th>Order Id</th>
            <th>Gift Card</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {data
            .sort((a: any, b: any) =>
              a.transactionDate > b.transactionDate ? -1 : 1
            )
            .map((order: any) => {
              return (
                <tr>
                  <td>{order.transactionDate.substr(0,10)}</td>
                  <td>{order.orderId}</td>
                  <td>{order.cardName}</td>
                  <td>${order.amount}</td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
    ) : (
      !loading && 
      
      <div className="order-error-container">
      <h2>Order History</h2>  
      <p>Order data is not available at this point. Please check again later.</p>
      </div>
    )}
    </>
    
  );
}
